import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import SideBar from "../../../components/sidebar/SideBar";
import { fetchRaiseAQuery } from "../../../../src/redux/slices/raiseAQuerySlice";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NewImage from "../../../assets/Image/new.png";

import {
  encryptData,
  decryptedData,
} from "../../../utils/useFullFunctions/encryptDecrypt";

const Queries = () => {
  const {
    raiseAQuery,
    totalCount: countDocument,
    loading,
  } = useSelector((state) => state.raiseQuery);

  let queryParams = new URLSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState({ currentPage: 0, totaltems: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [index, setIndex] = useState(1);
  const [searchText, setsearchtext] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterText = searchParams.getAll("text");
    const filterIndex = searchParams.getAll("index");

    const text = filterText.map((txt) => decryptedData(txt));

    let currentPage = filterIndex[0] || page?.currentPage;
    const startIndex = currentPage * rowsPerPage + 1;
    setIndex(startIndex);

    setsearchtext(text[0] || "");

    dispatch(
      fetchRaiseAQuery({
        searchData: text[0] || "",
        currentPage,
        rowsPerPage,
      })
    );
  }, [dispatch, location.search, page.currentPage, rowsPerPage]);

  useEffect(() => {
    if (loading === "fulfilled" && raiseAQuery) {
      setTotalCount(countDocument);
    }
  }, [loading, raiseAQuery, countDocument]);

  useEffect(() => {
    if (loading === "fulfilled") {
      setPage((prevState) => ({ ...prevState, totaltems: totalCount }));
    }
  }, [loading, totalCount]);

  /**pagination logic write here */
  const handleSearch = (e) => {
    e.preventDefault();

    queryParams.append("text", encryptData(searchText));
    queryParams.append("index", 0);

    navigate({
      search: queryParams.toString(),
      replace: true,
    });

    // setPage((prevState) => ({ ...prevState, currentPage: 0 }));
    // setSearchData(searchText);
  };

  const handleChangePage = (event, newPage) => {
    const existingSearchParams = new URLSearchParams(location.search);
    existingSearchParams.forEach((value, key) => {
      queryParams.append(key, value);
    });

    // Set the new page index
    queryParams.set("index", newPage);

    navigate({
      search: queryParams.toString(),
    });

    setPage((prevState) => ({ ...prevState, currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage((prevState) => ({ ...prevState, currentPage: 0 }));
  };

  function formateDate(date) {
    const utcDate = new Date(date);
    const indianLocaleTimeString = utcDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return indianLocaleTimeString;
  }

  return (
    <>
      <SideBar />
      <section className="">
        <Header />
        <div className="main_content_iner">
          <div className="container-fluid p-0">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_card card_height_100 mb_30">
                  <div className="white_card_header">
                    <div className="box_header m-0">
                      <div className="main-title">
                        <h3 className="m-0">Request Quotation</h3>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <form
                          onSubmit={handleSearch}
                          style={{ display: "flex", gap: "2px" }}
                        >
                          <div>
                            <input
                              type="search"
                              className="form-control"
                              id="search"
                              placeholder="Name Email Phone Number"
                              name="searchText"
                              value={searchText || ""}
                              onChange={(e) => setsearchtext(e.target.value)}
                            />
                          </div>
                          <div>
                            <button className="btn btn-success" type="submit">
                              <SearchIcon />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="white_card_body">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">MobNumber</th>
                            <th scope="col">Message</th>
                            <th scope="col" style={{ width: "15%" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {raiseAQuery &&
                            raiseAQuery?.length > 0 &&
                            raiseAQuery?.map((user, i) => (
                              <tr key={i + index}>
                                <th scope="row">{index + i}</th>
                                <td>
                                  {formateDate(user?.firstDocument?.createdAt)}
                                </td>
                                <td>{user?.firstDocument?.Name}</td>
                                <td>{user?.firstDocument?.Email}</td>
                                <td>{user?.firstDocument?.MobNumber}</td>
                                <td>{user?.firstDocument?.Message}</td>
                                <td>
                                  <span
                                    className="btn btn-default"
                                    style={{
                                      position: "relative",
                                      backgroundColor: "#fff",
                                      border: "1px solid #475B52",
                                      zoom: "80%",
                                    }}
                                  >
                                    {user?._id?.isNewData && (
                                      <img
                                        src={NewImage}
                                        alt="New"
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                    )}
                                    <Link
                                      to={`/admin/user-products?email=${user?.firstDocument?.Email}&mobNo=${user?.firstDocument?.MobNumber}&isNew=${user?._id?.isNewData}`}
                                      style={{
                                        color: "#475B52",
                                      }}
                                    >
                                      View Products &nbsp;&nbsp;
                                    </Link>
                                  </span>

                                  {/* <span className="btn btn-success">
                                    <Link
                                      to={`/admin/user-products?email=${user?.firstDocument?.Email}&mobNo=${user?.firstDocument?.MobNumber}`}
                                      style={{
                                        color: "#fff",
                                      }}
                                    >
                                      View Products
                                    </Link>
                                  </span> */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#1a2e45d7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      marginTop: "5px",
                    }}
                  >
                    <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[20, 25, 100, 200, 500]}
                      count={totalCount || 0}
                      rowsPerPage={rowsPerPage}
                      page={page.currentPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Queries;
