import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uiActon } from "../../../../../../redux/slices/ui-slice";
import { Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { axiosInstance } from "../../../../../../config";
import { toastError } from "../../../../../../utils/reactToastify";

const MyClient = () => {
  const disapatch = useDispatch();
  const [architectData, setArchitectData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState({ currentPage: 0, totaltems: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [index, setIndex] = useState(1);
  const [searchText, setSearchText] = useState({ text: "" });
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    setPage((prevState) => ({ ...prevState, totaltems: totalCount }));
  }, [totalCount]);
  /**searching logic write here */
  const handleSearch = (e) => {
    e.preventDefault();
    setPage((prevState) => ({ ...prevState, currentPage: 0 }));
    setSearchData(searchText.text);
  };
  /**pagination logic write here */
  const handleChangePage = (event, newPage) => {
    setPage((prevState) => ({ ...prevState, currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage((prevState) => ({ ...prevState, currentPage: 0 }));
  };

  useEffect(() => {
    async function getArchitectData() {
      try {
        let currentPage = page?.currentPage;
        const startIndex = currentPage * rowsPerPage + 1;
        setIndex(startIndex);
        const { data } = await axiosInstance.get(
          `/api/raiseAQuery/architect/client?search=${searchData}&page=${
            currentPage + 1
          }&limit=${rowsPerPage}`,
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );

        if (data?.success) {
          setArchitectData(data?.data);
          setTotalCount(data.totalCount);
        }
      } catch (error) {
        console.log(error);
        toastError(error?.response?.data?.message);
      }
    }
    getArchitectData();
  }, [searchData, page.currentPage, rowsPerPage]);

  useEffect(() => {
    disapatch(uiActon.title("My Clients"));
  }, [disapatch]);

  return (
    <Grid style={{ width: "100%", marginLeft: "20px" }}>
      <Card>
        <CardHeader subheader="Client List" />
        {/* <Title>Request For Price Application</Title> */}
        <div style={{ display: "flex", marginLeft: "800px", gap: "10px" }}>
          <form onSubmit={handleSearch} style={{ display: "flex", gap: "2px" }}>
            <div style={{ width: "1%" }}></div>
            <div>
              <input
                type="search"
                className="form-control"
                id="search"
                placeholder="Name Mobile Pin Code"
                name="searchText"
                value={searchText?.text || ""}
                onChange={(e) =>
                  setSearchText((prev) => ({
                    ...prev,
                    text: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <button className="btn btn-success" type="submit">
                <SearchIcon />
              </button>
            </div>
          </form>
        </div>

        <Divider />
        <CardContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Client Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile No</TableCell>
                {/* <TableCell>Message</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {architectData &&
                architectData?.length > 0 &&
                architectData?.map((architect, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{architect?.firstDocument?.clientId}</TableCell>
                    <TableCell>{architect?._id?.Name}</TableCell>
                    <TableCell>{architect?.firstDocument?.Email}</TableCell>
                    <TableCell>{architect?.firstDocument?.MobNumber}</TableCell>
                    {/* <TableCell>{architect?.firstDocument?.Message}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div
            style={{
              backgroundColor: "#1a2e45d7",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              marginTop: "5px",
            }}
          >
            <TablePagination
              component="div"
              className="text-white"
              rowsPerPageOptions={[20, 25, 100, 200, 500]}
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page.currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </CardContent>

        {/* <div className="main_content_iner">
    <div className="container-fluid p-0">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="white_card card_height_100 mb_30">
            <div className="white_card_header">
              <div className="box_header m-0">
                <div className="main-title">
                  <h3 className="m-0">Queries</h3>
                </div>
              </div>
            </div>
            <div className="white_card_body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">MobNumber</th>
                      <th scope="col">Message</th>
                      <th scope="col" style={{ width: "15%" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {architectData &&
                      architectData?.length > 0 &&
                      architectData?.map((architect, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{formateDate(architect?.createdAt)}</td>
                          <td>{architect?.Name}</td>
                          <td>{architect?.Email}</td>
                          <td>{architect?.MobNumber}</td>
                          <td>{architect?.Message}</td>
                          <td>
                            {architect?.customizedProductId
                              ?.isCustomizedProduct ? (
                              <span key={index}>
                                <Link
                                  to={`/customized-product/${architect?.customizedProductId?.Collection[0]?.url}/${architect?.customizedProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                                  style={{
                                    backgroundColor: "#198754",
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff",
                                  }}
                                >
                                  View
                                </Link>
                              </span>
                            ) : (
                              <span key={index}>
                                <Link
                                  to={`/product/${architect?.singleProductId?.Collection[0]?.url}/${architect?.singleProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                                  style={{
                                    backgroundColor: "#198754",
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff",
                                  }}
                                >
                                  View
                                </Link>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
      </Card>
    </Grid>
  );
};

export default MyClient;
