export const ActionTypes = {
  SET_PRODUCT_DESCRIPTION: "SET_PRODUCT_DESCRIPTION",
  SET_FRONT_COMBINATION: "SET_FRONT_COMBINATION",
  SET_SAF_COMBINATION: "SET_SAF_COMBINATION",
  SET_CB_COMBINATION: "SET_CB_COMBINATION",
  SET_IB_COMBINATION: "SET_IB_COMBINATION",
  SET_PRODUCT_DETAILS: "SET_PRODUCT_DETAILS",
  SET_BACK_SELECTED: "SET_BACK_SELECTED",
  SET_P_WIDTH: "SET_P_WIDTH",
  SET_P_HEIGHT: "SET_P_HEIGHT",
  SET_UOM: "SET_UOM",
  SET_REQUEST_PRODUCTS: "SET_REQUEST_PRODUCTS",
  SET_BUYING_TYPE: "SET_BUYING_TYPE",
  SET_WISHLIST_PRODUCTS: "SET_WISHLIST_PRODUCTS",
  SET_IS_WISHLIST_PRODUCTS: "SET_IS_WISHLIST_PRODUCTS",
  SET_STATUS: "SET_STATUS",
  SET_CART_PRODUCT: "SET_CART_PRODUCT",
  SET_CART_PRODUCT_IDS: "SET_CART_PRODUCT_IDS",
  SET_CART_PRODUCT_QUANTITY: "SET_CART_PRODUCT_QUANTITY",
  SET_WISHLIST_PRODUCT_OF_USER: "SET_WISHLIST_PRODUCT_OF_USER",
  SET_ORDER_PRODUCTS: "SET_ORDER_PRODUCTS",
  SET_ROOT_COLLECTION: "SET_ROOT_COLLECTION",
  UPDATE_COMBINATION: "UPDATE_COMBINATION",
};

export const initialState = {
  DescriptionsData: [],
  productDetails: {},
  FrontCombination: [],
  SAFCombination: [],
  CBCombination: [],
  IBCombination: [],
  updateCombination: [],
  backselected: "",
  rootCollection: [],
  P_Width: 0,
  P_Height: 0,
  UOM: [],
  requestProducts: {},
  buyingType: "Normal",
  wishlistProducts: [],
  isWishlistProducts: null,
  status: "removed",
  cartProduct: null,
  cartProductIds: [],
  cartProductQuantity: 1,
  wishlistProductOfUser: {},
  orderProducts: {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRODUCT_DESCRIPTION:
      return { ...state, DescriptionsData: action.payload };
    case ActionTypes.SET_FRONT_COMBINATION:
      return { ...state, FrontCombination: action.payload };
    case ActionTypes.SET_SAF_COMBINATION:
      return { ...state, SAFCombination: action.payload };
    case ActionTypes.SET_CB_COMBINATION:
      return { ...state, CBCombination: action.payload };
    case ActionTypes.SET_IB_COMBINATION:
      return { ...state, IBCombination: action.payload };
    case ActionTypes.SET_BACK_SELECTED:
      return { ...state, backselected: action.payload };
    case ActionTypes.SET_PRODUCT_DETAILS:
      return { ...state, productDetails: action.payload };
    case ActionTypes.SET_P_WIDTH:
      return { ...state, P_Width: action.payload };
    case ActionTypes.SET_P_HEIGHT:
      return { ...state, P_Height: action.payload };
    case ActionTypes.SET_UOM:
      return { ...state, UOM: action.payload };
    case ActionTypes.SET_REQUEST_PRODUCTS:
      return { ...state, requestProducts: action.payload };
    case ActionTypes.SET_BUYING_TYPE:
      return { ...state, buyingType: action.payload };
    case ActionTypes.SET_WISHLIST_PRODUCTS:
      return { ...state, wishlistProducts: action.payload };
    case ActionTypes.SET_IS_WISHLIST_PRODUCTS:
      return { ...state, isWishlistProducts: action.payload };
    case ActionTypes.SET_STATUS:
      return { ...state, status: action.payload };
    case ActionTypes.SET_CART_PRODUCT:
      return { ...state, cartProduct: action.payload };
    case ActionTypes.SET_CART_PRODUCT_IDS:
      return { ...state, cartProductIds: action.payload };
    case ActionTypes.SET_CART_PRODUCT_QUANTITY:
      return { ...state, cartProductQuantity: action.payload };
    case ActionTypes.SET_WISHLIST_PRODUCT_OF_USER:
      return { ...state, wishlistProductOfUser: action.payload };
    case ActionTypes.SET_ORDER_PRODUCTS:
      return { ...state, orderProducts: action.payload };
    case ActionTypes.SET_ROOT_COLLECTION:
      return { ...state, rootCollection: action.payload };
    case ActionTypes.UPDATE_COMBINATION:
      const { combinationType, fetchedCombinationData, res } = action.payload;
      return {
        ...state,
        [combinationType]: state[combinationType].map((combination) =>
          combination?.attributeId?._id ===
          fetchedCombinationData?.attributeId?._id
            ? {
                ...combination,
                parameterId: fetchedCombinationData?.parameterId?._id,
                combinations: res,
              }
            : combination
        ),
      };
    default:
      return state;
  }
};
