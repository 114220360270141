import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { useState } from "react";
import Modal from "../../../components/modal/Modal";
import CouponContent from "./CouponContent";
import discountImg from "../../../assets/Image/discount.png";

import { featchAvailableCoupons } from "../../../redux/slices/couponSlice";

import { formateAmount } from "../../../utils/formateAmount";

// const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];
// const payments = [
//   { name: "Card type:", detail: "Visa" },
//   { name: "Card holder:", detail: "Mr. John Smith" },
//   { name: "Card number:", detail: "xxxx-xxxx-xxxx-1234" },
//   { name: "Expiry date:", detail: "04/2024" },
// ];

const CustomPrimaryText = ({ discount, price }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Avatar
      src={discountImg}
      alt="_discount_icon"
      style={{ marginRight: 8, width: "25px", height: "25px" }}
    />
    <Stack>
      <Typography variant="body1">
        {discount ? (
          <>
            Coupon Applied <b>{discount?.CouponName}</b>
          </>
        ) : (
          "Apply Coupons"
        )}
      </Typography>
      {discount && (
        <Typography
          variant="body1"
          style={{
            color: "#1bd01b",
            fontSize: "14px",
          }}
        >
          {`Coupon Saving ${formateAmount(price - discount?.finalAmount)}`}
        </Typography>
      )}
    </Stack>
  </div>
);

export default function Review({
  products,
  price,
  formData,
  paymentType,
  setDiscount,
  discount,
}) {
  const dispatch = useDispatch();

  const { loading: couponLoading, availableCoupons } = useSelector(
    (state) => state.coupons
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleApplyCoupons = () => {
    if (!coupons.length) {
      dispatch(featchAvailableCoupons());
    } else {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    if (
      couponLoading === "fulfilled" &&
      availableCoupons &&
      availableCoupons?.length > 0
    ) {
      setCoupons(availableCoupons);
      setModalOpen(true);
    }
  }, [couponLoading, availableCoupons]);

  return (
    <Stack spacing={2}>
      <Modal
        show={isModalOpen}
        handleClose={handleClose}
        width="50%"
        height="auto"
        left="50%"
      >
        <CouponContent
          coupons={coupons}
          setDiscount={setDiscount}
          setModalOpen={setModalOpen}
          price={price}
        />
      </Modal>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={<CustomPrimaryText discount={discount} price={price} />}
          />
          <Typography
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={handleApplyCoupons}
            color="primary"
          >
            Select
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary="Products"
            secondary={`${products?.length} selected`}
          />
          <Typography variant="body2">{formateAmount(price)}</Typography>
        </ListItem>
        {discount && (
          <ListItem sx={{ px: 0 }}>
            <ListItemText primary="Cart Total" />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {formateAmount(price)}
            </Typography>
          </ListItem>
        )}
        {discount && (
          <ListItem sx={{ px: 0 }}>
            <ListItemText primary="Coupon Saving" />
            <Typography
              variant="body2"
              style={{
                color: "#1bd01b",
              }}
            >
              - {formateAmount(price - discount?.finalAmount)}
            </Typography>
          </ListItem>
        )}
        <ListItem sx={{ px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {discount
              ? formateAmount(discount?.finalAmount)
              : formateAmount(price)}
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Shipment details
          </Typography>
          <Typography
            gutterBottom
          >{`${formData?.firstName} ${formData?.lastName}`}</Typography>
          <Typography color="text.secondary" gutterBottom>
            {formData?.addressLine1} {formData?.addressLine2}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Payment details
          </Typography>
          <Grid container>
            {/* {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: "100%", mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    {payment.name}
                  </Typography>
                  <Typography variant="body2">{payment.detail}</Typography>
                </Stack>
              </React.Fragment>
            ))} */}
            <Typography variant="body1" color="text.secondary">
              {paymentType}
            </Typography>
          </Grid>
        </div>
      </Stack>
    </Stack>
  );
}
