import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";

// import AssignmentList from "../AssignmentList/AssignmentList";
import { uiActon } from "../../../../../../redux/slices/ui-slice";

// import ComplianceAndRegulation from "../Weights/ComplianceAndRegulation";
import Team from "../Weights/Team";
// import Project from "../Weights/Project";
// import Client from "../Weights/Client";

// import {
//   options as clientOption,
//   data as clentData,
// } from "../Chart/ClientChart";

// import { data as ourData } from "../Chart/OurGrowth";

// import { Bar } from "react-chartjs-2";

// import Share from "../Share/Share";

const Home = () => {
  const disapatch = useDispatch();

  useEffect(() => {
    disapatch(uiActon.title("Dashboard"));
  }, [disapatch]);

  return (
    <>
      <Grid item xs={12} md={6} lg={12}>
        <Team />
      </Grid>

      {/* <Share /> */}
    </>
  );
};

export default Home;
