import React from "react";
import { Link } from "react-router-dom";
import Carousel from "../../UI/Carousel";
import { REACT_APP_URL } from "../../config";
// import ImageMagnifier from "./ImageMagnifier";

const ProductSlide = ({
  product,
  productAccess,
  activeIndex = 0,
  path = "",
  collectiondetails,
}) => {
  console.log("product", product);
  console.log("collectiondetails", collectiondetails);

  return (
    <Carousel>
      {product.map((item, index) => (
        <div
          key={index}
          className={
            index === activeIndex ? `carousel-item active` : `carousel-item`
          }
        >
          <div className="text-center">
            {item?.[productAccess]?.ProductName}
          </div>
          <Link
            to={
              productAccess === "singleProductId"
                ? `/product/${collectiondetails?.url}/${item?.[productAccess].Urlhandle}`
                : productAccess === "productId"
                ? `/customized-product/${collectiondetails?.url}/${item?.[productAccess]?.Urlhandle}`
                : ""
            }
          >
            <div
              className="col-lg-12"
              style={{
                backgroundImage: `url(${REACT_APP_URL}/images/${path}/${item?.[productAccess]?.ProductImage[0]})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "85vh",
              }}
            />
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default ProductSlide;
