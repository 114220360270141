import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Preloader from "../../../../../components/preloader/Preloader";
import ArchitectOrderProducts from "./ArchitectOrderedProduct";
import Title from "./Title";
import Paper from "@mui/material/Paper";
import { axiosInstance } from "../../../../../config";

const ArchitectOrderProductList = () => {
  const { id } = useParams();
  const { userdetails } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState();
  const [products, setproducts] = useState({
    orderProducts: [],
    singleProducts: [],
    customizeProducts: [],
    singleDotProducts: [],
    customizeDotProducts: [],
    customizeComboProducts: [],
  });

  useEffect(() => {
    const fetchData = async (id, archId) => {
      try {
        setLoading(true);
        const { data } = await axiosInstance.get(
          `/api/order/architect/${id}/${archId}`,
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );

        if (data?.success) {
          const {
            singleProducts,
            customizeProducts,
            singleDotProducts,
            customizeDotProducts,
            customizeComboProducts,
          } = data;
          setproducts({
            orderProducts: data?.data[0],
            singleProducts,
            customizeProducts,
            singleDotProducts,
            customizeDotProducts,
            customizeComboProducts,
          });
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    if (id && userdetails && Object.keys(userdetails).length > 0) {
      fetchData(id, userdetails?._id);
    }
  }, [id, userdetails]);

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        marginLeft: "20px",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Preloader />
      ) : (
        <ArchitectOrderProducts
          orderProducts={products?.orderProducts}
          singleProducts={products?.singleProducts}
          customizeProducts={products?.customizeProducts}
          singleDotProducts={products?.singleDotProducts}
          customizeDotProducts={products?.customizeDotProducts}
          customizeComboProducts={products?.customizeComboProducts}
          isAdmin={false}
        />
      )}
    </Paper>
  );
};

export default ArchitectOrderProductList;
