import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../../../../../config";
import { toastError } from "../../../../../../utils/reactToastify";
import { TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { uiActon } from "../../../../../../redux/slices/ui-slice";
import {
  Box,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "../../../../../../UI/Modal";
import styles from "../../../../../../pages/admin/QuotationRequest/style.module.css";
import { updateRaiseAQuery } from "../../../../../../redux/slices/raiseAQuerySlice";

import {
  encryptData,
  decryptedData,
} from "../../../../../../utils/useFullFunctions/encryptDecrypt";

function RequestProduct() {
  const { userdetails } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let queryParams = new URLSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [architectData, setArchitectData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState({ currentPage: 0, totaltems: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [index, setIndex] = useState(1);
  const [searchText, setSearchText] = useState({ text: "" });
  // const [searchData, setSearchData] = useState("");
  const [error, setError] = useState(false);
  const [isArchitect, setIsArchitect] = useState(false);
  const [open, setOpen] = useState({
    isOpen: false,
    discountValue: null,
    id: "",
  });

  useEffect(() => {
    if (userdetails && Object.keys(userdetails).length > 0) {
      const role = userdetails.userRole?.find(
        (role) => role?.name === "Architect"
      );
      setIsArchitect(role);
    }
  }, [userdetails]);
  useEffect(() => {
    setPage((prevState) => ({ ...prevState, totaltems: totalCount }));
  }, [totalCount]);
  /**searching logic write here */
  const handleSearch = (e) => {
    e.preventDefault();

    queryParams.append("text", encryptData(searchText?.text));
    queryParams.append("index", 0);

    navigate({
      search: queryParams.toString(),
      replace: true,
    });

    // setPage((prevState) => ({ ...prevState, currentPage: 0 }));
    // setSearchData(searchText.text);
  };
  /**pagination logic write here */
  const handleChangePage = (event, newPage) => {
    const existingSearchParams = new URLSearchParams(location.search);
    existingSearchParams.forEach((value, key) => {
      queryParams.append(key, value);
    });

    // Set the new page index
    queryParams.set("index", newPage);

    setPage((prevState) => ({ ...prevState, currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage((prevState) => ({ ...prevState, currentPage: 0 }));
  };

  const getArchitectData = useCallback(
    async (searchData, currPage) => {
      try {
        let currentPage = currPage || page?.currentPage;
        const startIndex = currentPage * rowsPerPage + 1;
        setIndex(startIndex);
        const { data } = await axiosInstance.get(
          `/api/raiseAQuery/architect?search=${searchData}&page=${
            currentPage + 1
          }&limit=${rowsPerPage}`,
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (data?.success) {
          setArchitectData(data?.data);
          setTotalCount(data?.totalCount);
        }
      } catch (error) {
        console.log(error);
        toastError(error?.response?.data?.message);
      }
    },
    [page.currentPage, rowsPerPage]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterText = searchParams.getAll("text");
    const filterIndex = searchParams.getAll("index");

    const text = filterText.map((txt) => decryptedData(txt));

    let currentPage = filterIndex[0] || "";
    setSearchText({ text: text[0] || "" });

    getArchitectData(text[0] || "", currentPage);
  }, [getArchitectData, location.search]);

  useEffect(() => {
    dispatch(uiActon.title("Quotation Request"));
  }, [dispatch]);

  function formateDate(date) {
    const utcDate = new Date(date);
    const indianLocaleTimeString = utcDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return indianLocaleTimeString;
  }

  const handleDownloadQuotation = async (productType, id, clientName) => {
    try {
      setDownloading(true);
      const response = await axiosInstance.get(
        `api/pdf/quotation/${productType}/${id}`,
        {
          responseType: "blob",
        }
      );

      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });

      const a = document.createElement("a");
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      a.href = pdfUrl;
      a.download = clientName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      toastError(error?.response?.data?.message);
    }
    setDownloading(false);
  };

  const handleApplyDiscount = async () => {
    try {
      if (open?.id) {
        const { data } = await axiosInstance.put(
          `/api/raiseAQuery/${open?.id}`,
          { discount: open?.discountValue }
        );

        if (data?.data) {
          const updatedData = data?.data;

          if (updatedData && updatedData?._id) {
            const copiedData = [...architectData];

            const index = copiedData.findIndex(
              (raiseAQuery) =>
                raiseAQuery?._id?.toString() === updatedData?._id.toString()
            );

            if (index !== -1) {
              copiedData[index] = {
                ...copiedData[index],
                discount: updatedData.discount,
              };
            }
            setArchitectData(copiedData);
          }
        }
      }
      setOpen({ isOpen: false, discountValue: "", id: "" });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {open?.isOpen && (
        <Modal>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 6, sm: 6, md: 6 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="body"
                display="block"
                gutterBottom
                textAlign="center"
              >
                Add Discount (In %)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box display="flex" justifyContent="center">
                <TextField
                  id="outlined-basic"
                  type="number"
                  size="small"
                  variant="outlined"
                  className={styles["no-spin"]}
                  value={open?.discountValue || " "}
                  onChange={(e) => {
                    console.log("Discount changed", e.target.value);
                    console.log("Discount open", open.discountValue);
                    if (
                      isArchitect &&
                      userdetails &&
                      userdetails?.maxDiscount < e.target.value
                    ) {
                      setError(true);
                    } else {
                      setOpen((prevState) => ({
                        ...prevState,
                        discountValue: e.target.value,
                      }));
                    }
                  }}
                  helperText={
                    error
                      ? `Discount value can't be greater than ${userdetails?.maxDiscount}`
                      : ""
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: "10px" }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
              <Button
                color="success"
                sx={{ marginRight: 1 }}
                variant="contained"
                onClick={handleApplyDiscount}
              >
                Apply
              </Button>
              <Button
                color="warning"
                variant="contained"
                type="button"
                onClick={() => {
                  // setEditedTag({ id: "", tagName: "" });
                  setOpen({ isOpen: false, discountValue: "", id: "" });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
      <Grid style={{ width: "100%", marginLeft: "20px" }}>
        <Card>
          <CardHeader
            // subheader="Request For Price Application"
            title="Request For Quotation"
          />
          <div style={{ display: "flex", marginLeft: "800px", gap: "10px" }}>
            <form
              onSubmit={handleSearch}
              style={{ display: "flex", gap: "2px" }}
            >
              <div style={{ width: "1%" }}></div>
              <div>
                <input
                  type="search"
                  className="form-control"
                  id="search"
                  placeholder="Name Mobile"
                  name="searchText"
                  value={searchText?.text || ""}
                  onChange={(e) =>
                    setSearchText((prev) => ({
                      ...prev,
                      text: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <button className="btn btn-success" type="submit">
                  <SearchIcon />
                </button>
              </div>
            </form>
          </div>
          {/* <Title>Request For Price Application</Title> */}
          <Divider />
          <CardContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Date & Time</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile No</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell style={{ width: "27%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {architectData &&
                  architectData?.length > 0 &&
                  architectData?.map((architect, ind) => (
                    <TableRow key={ind}>
                      <TableCell>{ind + index}</TableCell>
                      <TableCell>{formateDate(architect?.createdAt)}</TableCell>
                      <TableCell>{architect?.Name}</TableCell>
                      <TableCell>{architect?.Email}</TableCell>
                      <TableCell>{architect?.MobNumber}</TableCell>
                      <TableCell>{architect?.Message}</TableCell>
                      <TableCell>{architect?.discount}</TableCell>
                      <TableCell style={{ zoom: "80%" }}>
                        {architect?.customizedProductId?.isCustomizedProduct ? (
                          <>
                            {/* <Link
                              target="_blank"
                              className="btn btn-success"
                              variant="contained"
                              size="small"
                              color="success"
                              to={`/customized-product/${architect?.customizedProductId?.Collection[0]?.url}/${architect?.customizedProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                            >
                              View
                            </Link> */}
                            <Link
                              target="_blank"
                              to={`/customized-product/${architect?.customizedProductId?.Collection[0]?.url}/${architect?.customizedProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                              >
                                View
                              </Button>
                            </Link>
                            &nbsp;
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              disabled={downloading}
                              onClick={() =>
                                handleDownloadQuotation(
                                  "customizeProduct",
                                  architect?._id,
                                  architect?.Name
                                )
                              }
                            >
                              Quotation
                            </Button>
                          </>
                        ) : (
                          <span key={index}>
                            {/* <Link
                              target="_blank"
                              className="btn btn-success"
                              size="small"
                              variant="contained"
                              color="success"
                              to={`/product/${architect?.singleProductId?.Collection[0]?.url}/${architect?.singleProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                            >
                              View
                            </Link> */}
                            <Link
                              target="_blank"
                              to={`/product/${architect?.singleProductId?.Collection[0]?.url}/${architect?.singleProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                              >
                                View
                              </Button>
                            </Link>
                            &nbsp;
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              disabled={downloading}
                              onClick={() =>
                                handleDownloadQuotation(
                                  "singleProduct",
                                  architect?._id,
                                  architect?.Name
                                )
                              }
                            >
                              Quotation
                            </Button>
                          </span>
                        )}
                        &nbsp;
                        <Button
                          variant="contained"
                          size="small"
                          color="warning"
                          onClick={() =>
                            setOpen({
                              isOpen: true,
                              discountValue: architect?.discount,
                              id: architect?._id,
                            })
                          }
                        >
                          Add Discount
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>
          <div
            style={{
              backgroundColor: "#1a2e45d7",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              marginTop: "5px",
            }}
          >
            <TablePagination
              component="div"
              className="text-white"
              rowsPerPageOptions={[20, 25, 100, 200, 500]}
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page.currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          {/* <div className="main_content_iner">
        <div className="container-fluid p-0">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="white_card card_height_100 mb_30">
                <div className="white_card_header">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <h3 className="m-0">Queries</h3>
                    </div>
                  </div>
                </div>
                <div className="white_card_body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No.</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">MobNumber</th>
                          <th scope="col">Message</th>
                          <th scope="col" style={{ width: "15%" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {architectData &&
                          architectData?.length > 0 &&
                          architectData?.map((architect, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{formateDate(architect?.createdAt)}</td>
                              <td>{architect?.Name}</td>
                              <td>{architect?.Email}</td>
                              <td>{architect?.MobNumber}</td>
                              <td>{architect?.Message}</td>
                              <td>
                                {architect?.customizedProductId
                                  ?.isCustomizedProduct ? (
                                  <span key={index}>
                                    <Link
                                      to={`/customized-product/${architect?.customizedProductId?.Collection[0]?.url}/${architect?.customizedProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                                      style={{
                                        backgroundColor: "#198754",
                                        padding: "4px",
                                        borderRadius: "3px",
                                        color: "#fff",
                                      }}
                                    >
                                      View
                                    </Link>
                                  </span>
                                ) : (
                                  <span key={index}>
                                    <Link
                                      to={`/product/${architect?.singleProductId?.Collection[0]?.url}/${architect?.singleProductId?.Urlhandle}?rfp_id=${architect?._id}`}
                                      style={{
                                        backgroundColor: "#198754",
                                        padding: "4px",
                                        borderRadius: "3px",
                                        color: "#fff",
                                      }}
                                    >
                                      View
                                    </Link>
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        </Card>
      </Grid>
    </>
  );
}

export default RequestProduct;
