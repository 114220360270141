import React, { useState } from "react";

import { useSelector } from "react-redux";

import Share from "@mui/icons-material/Share";

// Material components
// import { Typography } from "@mui/material";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import GroupIcon from "@mui/icons-material/Group";
// import MoneyIcon from "@mui/icons-material/Money";
import Paper from "@mui/material/Paper";
import { MdContentCopy } from "react-icons/md";
// Component styles
// import theme from "../theme/index";
// import classes from "./styles.module.css";
import { REACT_APP_URL } from "../../../../../../config";

const handleShare = async (url) => {
  try {
    if (navigator.share) {
      await navigator.share({
        title: document.title,
        url: `${REACT_APP_URL}/architect/${url}`,
      });
    } else {
      throw new Error("Web Share API not supported");
    }
  } catch (error) {
    console.error("Error sharing:", error);
    // Handle the error or provide fallback behavior
  }
};

const Team = () => {
  const { userdetails } = useSelector((state) => state.auth);

  const [copied, setCopied] = useState(false);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(`${REACT_APP_URL}/architect/${url}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <div
        className="container"
        style={{ height: "80vh", padding: "20px 70px 20px 70px" }}
      >
        <div className="col-lg-8" style={{ float: "left" }}>
          <div className="row">
            <div className="col-lg-11" style={{ float: "left" }}>
              <h4 style={{ textTransform: "uppercase", fontWeight: "600" }}>
                Your Profile
              </h4>
            </div>
            <div
              className="col-lg-1"
              style={{ float: "left", cursor: "pointer" }}
            >
              <Share onClick={() => handleShare(userdetails?.Url)} />

              {/* <MdContentCopy style={{ fontSize: "20px" }}  onClick={()=>handleCopy(userdetails?.Url)}/> */}
              {/* <button className="btn btn-primary">Copy URL</button> &nbsp;
              <button className="btn btn-primary">Share Page</button> */}
            </div>
          </div>
          <br></br>
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="inputEmail4">Architect Name</label>
                <input
                  className="form-control"
                  value={userdetails.Name}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Email</label>
                <input
                  className="form-control"
                  value={userdetails.Email}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Mobile No.</label>
                <input
                  className="form-control"
                  value={userdetails.MobNumber}
                  disabled
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="inputEmail4">Firm Name</label>
                <input
                  className="form-control"
                  value={userdetails.firmName}
                  disabled
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="inputEmail4">Address</label>
                <input
                  className="form-control"
                  value={userdetails.Address}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Contact Person Name</label>
                <input
                  className="form-control"
                  value={userdetails?.contactPersonName}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Contact Person No.</label>
                <input
                  className="form-control"
                  value={userdetails?.contactPersonMobNumber}
                  disabled
                />
              </div>
            </div>
          </form>
          {/* <button */}
        </div>
        <div
          className="col-lg-4"
          style={{ float: "left", padding: "100px 10px 40px 70px" }}
        >
          <img
            src={`${REACT_APP_URL}/images/architect/${userdetails?.image}`}
            style={{ width: "100%", borderRadius: "50%" }}
            alt="profile_image"
            className="profile"
          />
        </div>
      </div>
    </Paper>
  );
};

export default Team;
