import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { uiActon } from "../../../../../redux/slices/ui-slice";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Preloader from "../../../../../components/preloader/Preloader";
import ArchitectOrderList from "./ArchitectOrderList";
// import Title from "./Title";
import { axiosInstance } from "../../../../../config";
import { Link } from "react-router-dom";

export default function Orders() {
  const disapatch = useDispatch();

  const { userdetails } = useSelector((state) => state.auth);
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setLoading(true);
        const { data } = await axiosInstance.get(`/api/order/architect/${id}`, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });

        if (data?.success) {
          setAllOrders(data?.data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    if (userdetails && Object.keys(userdetails).length > 0) {
      fetchData(userdetails?._id);
    }
  }, [userdetails]);

  useEffect(() => {
    disapatch(uiActon.title("My Orders"));
  }, [disapatch]);

  return (
    <React.Fragment>
      {/* <Title>Orders</Title> */}
      {loading ? (
        <Preloader />
      ) : allOrders && allOrders?.length > 0 ? (
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <ArchitectOrderList allOrders={allOrders} />
        </Paper>
      ) : (
        <div style={{ width: "100%" }}>
          <br />
          <br />
          <br />
          <center>
            <h2>Your Order History is Empty!</h2>
            <p>
              It looks like you haven't placed any orders with us yet. Once you
              start shopping, all your orders will appear here.
            </p>
            <br></br>
            <Link
              to="/"
              style={{
                textAlign: "center",
                padding: "10px 20px",
                backgroundColor: "black",
                color: "white",
                textDecoration: "none",
                marginTop: "20px",
              }}
            >
              Browse Our Products Now
            </Link>
            <br />
            <br />
            <br />
            <br />
          </center>
        </div>
      )}

      {/* <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.shipTo}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{`$${row.amount}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link> */}
    </React.Fragment>
  );
}
