import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "../../../redux/slices/orderSlice";
import Preloader from "../../../components/preloader/Preloader";
import TopHeader from "../../../components/topheader/TopHeader";
import SideBar from "../../../components/sidebar/SideBar";
import OrderList from "../../../components/order/OrderList";
import { TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  encryptData,
  decryptedData,
} from "../../../utils/useFullFunctions/encryptDecrypt";

const Order = () => {
  const dispatch = useDispatch();
  const {
    loading,
    orders,
    totalCount: tCount,
  } = useSelector((state) => state.orders);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState({ currentPage: 0, totaltems: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // const [index, setIndex] = useState(1);
  const [allOrders, setAllOrders] = useState([]);
  const [searchText, setSearchText] = useState({ text: "" });
  // const [searchData, setSearchData] = useState("");

  let queryParams = new URLSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterText = searchParams.getAll("text");
    const filterIndex = searchParams.getAll("index");

    const text = filterText.map((txt) => decryptedData(txt));

    let currentPage = filterIndex[0] || page?.currentPage;

    setSearchText({ text: text[0] || "" });

    // const startIndex = currentPage * rowsPerPage + 1;
    // setIndex(startIndex);
    dispatch(
      fetchOrders({ searchData: text[0] || "", currentPage, rowsPerPage })
    );
  }, [dispatch, page.currentPage, rowsPerPage, location.search]);

  useEffect(() => {
    if (loading === "fulfilled" && orders && orders?.length > 0) {
      setAllOrders(orders);
      setTotalCount(tCount);
    }
  }, [loading, orders, tCount]);

  useEffect(() => {
    if (loading === "fulfilled") {
      setPage((prevState) => ({ ...prevState, totaltems: totalCount }));
    }
  }, [loading, totalCount]);
  /**searching logic write here */
  const handleSearch = (e) => {
    e.preventDefault();

    queryParams.append("text", encryptData(searchText?.text));
    queryParams.append("index", 0);

    navigate({
      search: queryParams.toString(),
      replace: true,
    });

    // setPage((prevState) => ({ ...prevState, currentPage: 0 }));
    // setSearchData(searchText.text);
  };
  /**pagination logic write here */
  const handleChangePage = (event, newPage) => {
    setPage((prevState) => ({ ...prevState, currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage((prevState) => ({ ...prevState, currentPage: 0 }));
  };

  return (
    <>
      <SideBar />
      <section className="">
        <TopHeader />

        <div className="main_content_iner ">
          <div className="container-fluid p-0">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_card card_height_100 mb_30">
                  <div className="white_card_header">
                    <div className="box_header m-0">
                      <div className="main-title">
                        <h3 className="m-0">order</h3>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <form
                            onSubmit={handleSearch}
                            style={{ display: "flex", gap: "2px" }}
                          >
                            <div style={{ width: "1%" }}></div>
                            <div>
                              <input
                                type="search"
                                className="form-control"
                                id="search"
                                placeholder="Name Mobile Pin Code"
                                name="searchText"
                                value={searchText?.text || ""}
                                onChange={(e) =>
                                  setSearchText((prev) => ({
                                    ...prev,
                                    text: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div>
                              <button className="btn btn-success" type="submit">
                                <SearchIcon />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white_card_body">
                    {loading === "pending" ? (
                      <Preloader />
                    ) : allOrders && allOrders?.length > 0 ? (
                      <OrderList allOrders={allOrders} isAdmin={true} />
                    ) : (
                      <p style={{ textAlign: "center" }}>No Orders To Show </p>
                    )}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#1a2e45d7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      marginTop: "5px",
                    }}
                  >
                    <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[20, 25, 100, 200, 500]}
                      count={totalCount || 0}
                      rowsPerPage={rowsPerPage}
                      page={page.currentPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_part">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer_iner text-center">
                  <p>
                    Designed & Developed By{" "}
                    <a href="http://marwariplus.com/">InnovateX Technology</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Order;
