import SideBar from "../../../components/sidebar/SideBar";
import { useEffect, useState } from "react";
import TopHeader from "../../../components/topheader/TopHeader";
import Preloader from "../../../components/preloader/Preloader";
import { toastError } from "../../../utils/reactToastify";
import { axiosInstance } from "../../../config";

const PartnerWithus = () => {
  const [partnerWitUs, setPartnerWithUs] = useState([]);
  const [loading, setLoading] = useState(false);

  async function featchAllData() {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get("/api/partner-with-us");
      if (data.success) {
        setPartnerWithUs(data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error?.response?.data?.message);
    }
  }
  useEffect(() => {
    featchAllData();
  }, []);

  return (
    <>
      <SideBar />
      <section className="">
        <TopHeader />

        <div className="main_content_iner ">
          <div className="container-fluid p-0">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="white_card card_height_100 mb_30">
                  <div className="main-title">
                    <h3 className="m-0">Partner With Us</h3>
                  </div>
                  <div className="white_card_body">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">City</th>
                            <th scope="col">Message</th>

                            {/* <th scope="col">Tags</th> */}

                            {/* <th scope="col">Vendor</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Preloader />
                          ) : (
                            <>
                              {partnerWitUs &&
                                partnerWitUs.map((p, index) => (
                                  <tr key={p._id}>
                                    <th scope="row">{index + 1}</th>

                                    <td>{p?.name}</td>
                                    <td>{p?.mobileNo}</td>
                                    <td>{p?.email}</td>
                                    <td>{p?.city}</td>
                                    <td>{p?.message} </td>

                                    <td>
                                      {/* <span>
                                      <Link
                                        to={`/admin/edit-enquiry/${p._id}`}
                                        style={{
                                          backgroundColor: "#198754",
                                          padding: "7px",
                                          borderRadius: "8px",
                                          color: "#fff",
                                        }}
                                      >
                                        <FiEdit />
                                      </Link>
                                    </span> */}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_part">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer_iner text-center">
                  <p>
                    Designed & Developed By{" "}
                    <a href="http://marwariplus.com/">InnovateX Technology</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerWithus;
