import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import SingleProduct from "../SingleProduct/SingleProduct";
import CustomizeProduct from "../CustomizeProduct/CustomizeProduct";
import DotProduct from "../DotProduct/DotProduct";
import ComboProduct from "../ComboProduct/ComboProduct";

import { uiActon } from "../../../../../../redux/slices/ui-slice";

const Favourite = () => {
  const disapatch = useDispatch();

  useEffect(() => {
    disapatch(uiActon.title("My favourite"));
  }, [disapatch]);

  return (
    <>
      <SingleProduct />
      <CustomizeProduct />
      <DotProduct />
      <ComboProduct />
    </>
  );
};

export default Favourite;
