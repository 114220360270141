import React from "react";
import { Typography } from "@mui/material";
import "./CouponContent.css";

const CouponContent = ({ coupons, price, setDiscount, setModalOpen }) => {
  // const calculateFlat = (couponAmmount, minAmmount) => {
  //   if (minAmmount >= couponAmmount) {
  //     const total = price - couponAmmount;
  //     const finalDiscount = Math.floor(total);
  //     setCalDiscount(finalDiscount);
  //   }
  // };
  // const calculatePercentage = (percentage, maxDiscountAmmount) => {
  //   const discountAmmount = Math.floor((price * percentage) / 100);
  //   const finalDiscount = Math.min(discountAmmount, maxDiscountAmmount);

  //   const total = price - finalDiscount;
  //   setCalDiscount(total);
  // };

  const handleApplyCoupons = (discount) => {
    let finalAmount = price;
    if (discount?.CouponType === "Flat") {
      finalAmount = price - discount?.CouponAmount;
    } else if (discount && discount?.CouponType === "Percentage") {
      const discountAmmount = Math.floor(
        (price * discount?.CouponPercentage) / 100
      );
      const finalDiscount = Math.min(discountAmmount, discount?.MaxDiscount);

      finalAmount = price - finalDiscount;
    }

    setDiscount({ ...discount, finalAmount });
    setModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="container" style={{ padding: "30px" }}>
        <p
          style={{
            textAlign: "center",
            fontSize: "20px",
            textTransform: "uppercase",
            letterSpacing: "1px",
          }}
        >
          Available Coupons
        </p>
        <table className="table-striped">
          <tbody>
            {coupons &&
              coupons.length > 0 &&
              coupons.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "85%" }}>
                    {item?.CouponType === "Flat" ? (
                      <Typography variant="body1">
                        {`Flat ₹ ${
                          item?.CouponAmount
                        }  discount on minimum purchase ₹ ${
                          item?.Min_Order_value_in_Flat
                        } valid upto ${item?.ExpireDate.slice(0, 10)}`}
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {`Upto ${item.CouponPercentage} % discount,max upto ₹ ${
                          item.MaxDiscount
                        } valid upto ${item.ExpireDate.slice(0, 10)}`}
                      </Typography>
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button
                      style={{
                        color: "#fff",
                        backgroundColor:
                          item?.CouponType === "Flat" &&
                          price < item?.Min_Order_value_in_Flat
                            ? "#475b529e"
                            : "#475B52",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                      disabled={
                        item?.CouponType === "Flat" &&
                        price < item?.Min_Order_value_in_Flat
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleApplyCoupons(item);
                      }}
                    >
                      Apply
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CouponContent;
