import React from "react";

import Button from "@mui/material/Button";

import { REACT_APP_URL } from "../../config";
import { Link } from "react-router-dom";

const CustomizeDotProductCard = ({
  product,
  handleOpen,
  orderProducts,
  setStatus,
  handleCancleClick,
  handleAssignArchitectClick,
  isAdmin,
}) => {
  return (
    <tr>
      <td className={product?.isCancelled ? "redrow" : ""}>
        <div className="ImageContainer" style={{ position: "relative" }}>
          {product?.dotProductImageIds[0]?.image && (
            <Link to={`/customize-room-idea/${product?.customizeDotProductId}`}>
              <img
                loading="lazy"
                src={`${REACT_APP_URL}/images/dotimage/${product?.dotProductImageIds[0]?.image}`}
                className="card-img-top RoomIdeaImageStyle"
                alt={product?.name}
                style={{ height: "15vh" }}
              />
            </Link>
          )}
        </div>
      </td>
      <td className={product?.isCancelled ? "redrow" : ""}>
        <p style={{ fontSize: "17px", backgroundColor: "transparent" }}>
          {product?.name}{" "}
        </p>
      </td>{" "}
      <td
        className={product?.isCancelled ? "redrow" : ""}
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        ₹ {product?.productAmount}
      </td>
      <td
        className={product?.isCancelled ? "redrow" : ""}
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        {product?.quantity}
      </td>
      {isAdmin && (
        <td
          className={product?.isCancelled ? "redrow" : ""}
          style={{ textAlign: "center", fontWeight: "600" }}
        >
          {product?.archId?.Name ? (
            product?.archId?.Name
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              title="click here to assign architect"
              onClick={() =>
                handleAssignArchitectClick(
                  orderProducts?._id,
                  product?._id,
                  "customizeDotProducts"
                )
              }
            >
              Assign Architect
            </Button>
          )}
        </td>
      )}
      <td
        className={product?.isCancelled ? "redrow" : ""}
        style={{ textAlign: "right", fontWeight: "600" }}
      >
        {!isAdmin && !product?.isCancelled && (
          <Button
            onClick={() => {
              handleCancleClick(
                orderProducts?._id,
                product?._id,
                "customizeDotProducts"
              );
            }}
            variant="contained"
            disableElevation
            color="error"
            size="small"
          >
            Cancel
          </Button>
        )}
        &nbsp;
        {!isAdmin && (
          <Button
            onClick={() => setStatus(product)}
            variant="contained"
            disableElevation
            color="success"
            size="small"
            style={{ backgroundColor: "#475B52" }}
          >
            Status
          </Button>
        )}
        &nbsp;
        {isAdmin && (
          <Button
            href={`/admin/order/status/customizeDotProducts/${orderProducts?._id}/${product?._id}`}
            variant="outlined"
            color="success"
            size="small"
          >
            Update Status
          </Button>
        )}
        &nbsp;
        {isAdmin && (
          <Button
            href={`/customize-room-idea/${product?.customizeDotProductId}`}
            variant="outlined"
            color={product?.isCancelled ? "primary" : "warning"}
            size="small"
          >
            View Product
          </Button>
        )}
        {product?.isCancelled && (
          <p style={{ textAlign: "left" }}>Reason : {product?.cancelReason}</p>
        )}
      </td>
    </tr>
  );
};

export default CustomizeDotProductCard;
