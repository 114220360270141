import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { REACT_APP_URL } from "../../../config";
import { Link } from "react-router-dom";

const CatalougeSlider = ({ catalouge }) => {
  // const [showAllList, setShowAllList] = useState(reviews.map(() => false));
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const toggleShowAll = (index) => {
  //   const newShowAllList = [...showAllList];
  //   newShowAllList[index] = !newShowAllList[index];
  //   setShowAllList(newShowAllList);
  // };

  return (
    <div className="container-fluid" style={{ padding: "10px 20px 10px 20px" }}>
      <Slider {...settings}>
        {catalouge &&
          catalouge.length > 0 &&
          catalouge?.map((catalogue, i) => (
            <Link
              key={i}
              to={`${REACT_APP_URL}/images/catalogue/${catalogue?.pdfUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="col-lg-12" style={{ float: "left" }}>
                <center>
                  <div className="col-lg-10">
                    <img
                      className="catalogueimage"
                      src={`${REACT_APP_URL}/images/catalogue/${catalogue?.image}`}
                      alt="_catalogue"
                      style={{
                        width: "100%",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                      }}
                    />
                    <br></br>
                    <p
                      style={{
                        color: "#fff",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      {catalogue.name}
                    </p>
                  </div>
                </center>
              </div>
            </Link>
          ))}
      </Slider>
    </div>
  );
};
export default CatalougeSlider;
