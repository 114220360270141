import React from "react";

import "./styles.css";
import { REACT_APP_URL } from "../../config";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const CustomizeProductCard = ({
  product,
  combination,
  orderProducts,
  setStatus,
  handleCancleClick,
  handleAssignArchitectClick,
  isAdmin,
}) => {
  return (
    <tr>
      <td className={combination?.isCancelled ? "redrow" : ""}>
        <Link
          to={`/customized-product/${product?.Collection[0]?.url}/${
            product?.Urlhandle
          }?productType=${"customizeProducts"}&orderId=${
            orderProducts?._id
          }&orderItemId=${combination?._id}`}
        >
          <ImageCreation
            customuizedProductFront={combination?.FrontCombinations}
          />
        </Link>
      </td>
      <td className={combination?.isCancelled ? "redrow" : ""}>
        <p style={{ fontSize: "17px", backgroundColor: "transparent" }}>
          {product?.ProductName}
        </p>
      </td>
      <td
        className={combination?.isCancelled ? "redrow" : ""}
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        ₹ {combination?.productAmount}
      </td>
      <td
        className={combination?.isCancelled ? "redrow" : ""}
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        {combination?.quantity}
      </td>
      {isAdmin && (
        <td
          className={combination?.isCancelled ? "redrow" : ""}
          style={{ textAlign: "center", fontWeight: "600" }}
        >
          {combination?.archId?.Name ? (
            combination?.archId?.Name
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              title="click here to assign architect"
              onClick={() =>
                handleAssignArchitectClick(
                  orderProducts?._id,
                  product?._id,
                  "customizeProducts"
                )
              }
            >
              Assign Architect
            </Button>
          )}
        </td>
      )}
      <td
        className={combination?.isCancelled ? "redrow" : ""}
        style={{ textAlign: "right", fontWeight: "600" }}
      >
        {!isAdmin && !combination?.isCancelled && (
          <Button
            onClick={() => {
              handleCancleClick(
                orderProducts?._id,
                combination?._id,
                "customizeProducts"
              );
            }}
            variant="contained"
            disableElevation
            color="error"
            size="small"
          >
            Cancel
          </Button>
        )}
        &nbsp;
        {!isAdmin && (
          <Button
            onClick={() => setStatus(combination)}
            variant="contained"
            disableElevation
            color="success"
            size="small"
            style={{ backgroundColor: "#475B52" }}
          >
            Status
          </Button>
        )}
        &nbsp;
        {isAdmin && (
          <Button
            href={`/admin/order/status/customizeProducts/${orderProducts?._id}/${combination?._id}`}
            variant="outlined"
            color="success"
            size="small"
          >
            Update Status
          </Button>
        )}
        &nbsp;
        {isAdmin && (
          <Button
            href={`/customized-product/${product?.Collection[0]?.url}/${
              product?.Urlhandle
            }?productType=${"customizeProducts"}&orderId=${
              orderProducts?._id
            }&orderItemId=${combination?._id}`}
            variant="outlined"
            color={combination?.isCancelled ? "primary" : "warning"}
            size="small"
          >
            View Product
          </Button>
        )}
        {combination?.isCancelled && (
          <p style={{ textAlign: "left" }}>
            Reason : {combination?.cancelReason}
          </p>
        )}
      </td>
    </tr>
  );
};

export default CustomizeProductCard;

const ImageCreation = ({ customuizedProductFront }) => {
  return (
    <>
      <div
        style={{
          position: "relative", // Add position relative to contain absolute positioning of child images
          backgroundColor: "transparent",
        }}
      >
        {customuizedProductFront &&
          customuizedProductFront.length > 0 &&
          customuizedProductFront.map((img, i) => (
            <img
              key={i}
              src={`${REACT_APP_URL}/images/parameterPosition/${img?.pngImage}`}
              alt="Preview"
              style={{
                position: i === 0 ? "relative" : "absolute", // Position images absolutely within the parent div
                left: `${img?.positionX * 100}%`,
                top: `${img?.positionY * 100}%`,
                // width: "300px",
                height: "15vh",
                objectFit: "cover", // Ensure the image fills its container without distortion
                zIndex: img?.attributeId?.BurgerSque, // Use zIndex from the image attribute
              }}
            />
          ))}
      </div>
    </>
  );
};
