import React from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import BackgroundImageRight from "../../assets/Image/BackgroundImageRight.png";
import { formateAmount } from "../../utils/formateAmount";
import NewImage from "../../assets/Image/new.png";

import { downloadOrderFiles } from "../../redux/slices/orderSlice";

function formateDate(date) {
  const utcDate = new Date(date);
  const indianLocaleTimeString = utcDate.toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
  });
  return indianLocaleTimeString;
}

const OrderList = ({ allOrders, isAdmin = false }) => {
  const dispatch = useDispatch();

  const handleClick = (orderId, name) => {
    dispatch(downloadOrderFiles({ orderId, name }));
  };

  return (
    <div
      className="row"
      style={{
        backgroundImage: `url(${BackgroundImageRight})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        clear: "both",
      }}
    >
      <div className="container" style={{ width: "1200px" }}>
        <table className="table table-spriped">
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Order Id
              </th>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Name
              </th>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Mobile No.
              </th>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Address
              </th>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Pin Code
              </th>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Order Time
              </th>
              <th
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  backgroundColor: "#475B52",
                }}
              >
                Amount
              </th>

              <th
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  color: "#fff",
                  width: "20%",
                  backgroundColor: "#475B52",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {allOrders &&
              allOrders.length > 0 &&
              allOrders.map((user) => (
                <tr key={user?._id}>
                  <td style={{ fontSize: "15px" }}>{user?.orderId}</td>
                  <td style={{ fontSize: "15px" }}>
                    {user?.firstName} {user?.lastName}
                  </td>
                  <td style={{ fontSize: "15px" }}>{user?.phoneNumber}</td>
                  <td style={{ fontSize: "15px" }}>
                    {`${user?.city}
                      ${user?.state}`}
                  </td>
                  <td style={{ fontSize: "15px" }}>{user?.pinCode}</td>
                  <td style={{ fontSize: "15px" }}>
                    {formateDate(user?.createdAt)}
                  </td>
                  <td style={{ fontSize: "15px" }}>
                    {formateAmount(user?.amount)}
                  </td>
                  <td style={{ zoom: "70%" }}>
                    <center>
                      <Link
                        to={
                          isAdmin
                            ? `/admin/order/${user?._id}`
                            : `/orders/${user?._id}`
                        }
                      >
                        <span
                          className="btn btn-default"
                          style={{
                            position: "relative",
                            backgroundColor: "#fff",
                            border: "1px solid #475B52",
                          }}
                        >
                          {isAdmin && user?.isNewOrder && (
                            <img
                              src={NewImage}
                              alt="New"
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          )}
                          <button
                            target="_blank"
                            style={{
                              color: "#475B52",
                              backgroundColor: "#fff",
                              border: "none",
                            }}
                          >
                            View Details &nbsp;&nbsp;
                          </button>
                        </span>
                        {/* <button
                          style={{
                            backgroundColor: "#475B52",
                            color: "#fff",
                            border: "none",
                            padding: "7px 15px 7px 15px",
                          }}
                        >
                          View Details
                        </button> */}
                      </Link>
                      &nbsp; &nbsp;
                      {user &&
                        isAdmin &&
                        user?.orderImages &&
                        user?.orderImages?.length > 0 && (
                          <button
                            title="Download order details"
                            onClick={() =>
                              handleClick(user?._id, user?.firstName)
                            }
                            className="btn btn-success"
                          >
                            Download
                          </button>
                        )}
                    </center>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderList;
