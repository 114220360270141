import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import StatusModal from "../modal/Modal";
import CancelModal from "../../UI/Modal";
import "./OrderProducts.css";
import SingleProductCard from "./SingleProductCard";
import CustomizeProductCard from "./CustomizeProductCard";
import SingleDotProductCard from "./SingleDotProductCard";
import CustomizeDotProductCard from "./CustomizeDotProductCard";
import BackgroundImageRight from "../../assets/Image/BackgroundImageRight.png";
import CustomizedCombinationCard from "./CustomizedCombinationCard";
import { formateAmount } from "../../utils/formateAmount";

import { fetchArchitect } from "../../redux/slices/architectSlice";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Fade } from "@mui/material";

import ProductMagnification from "../productMagnification/ProductMagnification.jsx";

import {
  cancelOrder,
  assignArchitectOnOrder,
} from "../../redux/slices/orderSlice";

import {
  TextField,
  Typography,
  Button,
  Grid,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { REACT_APP_URL } from "../../config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  height: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function formateDate(date) {
  const utcDate = new Date(date);
  const indianLocaleTimeString = utcDate.toLocaleString("en-IN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    timeZone: "Asia/Kolkata",
  });
  return indianLocaleTimeString;
}

let orderId;
let orderItemId;
let productType;

const OrderProducts = ({
  orderProducts,
  singleProducts,
  customizeProducts,
  singleDotProducts,
  customizeDotProducts,
  customizeComboProducts,
  isAdmin = false,
}) => {
  const { loading, architects } = useSelector((state) => state.architect);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState({
    cancelModel: false,
    architectModel: false,
  });
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState(null);
  const [selectBoxOpen, setSelectBoxOpen] = useState(false);
  const [selectedArchitect, setSelectArchitect] = useState(null);
  const [architect, setArchitect] = useState([]);

  const [openZoomModal, setOpenZoomModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const handleOpenZoomModal = () => setOpenZoomModal(true);
  const handleCloseZoomModal = () => setOpenZoomModal(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setStatus(null);
    setOpen(false);
  };

  useEffect(() => {
    if (status) {
      const imageNames = status.status?.map((item) => item.statusImage).flat();
      setProductImages(imageNames);
      setOpen(true);
    }
  }, [status]);

  const handleCancleClick = (id, itemId, productCategory) => {
    orderId = id;
    orderItemId = itemId;
    productType = productCategory;

    setOpenModal((prevState) => ({
      ...prevState,
      cancelModel: true,
    }));
  };

  const handleSubmitClick = async () => {
    dispatch(
      cancelOrder({
        id: orderId,
        orderdata: { isCancelled: true, cancelReason: reason },
        orderItemId,
        productType: productType,
      })
    );
  };

  const handleAssignArchitectClick = (id, itemId, productCategory) => {
    orderId = id;
    orderItemId = itemId;
    productType = productCategory;

    setOpenModal((prevState) => ({
      ...prevState,
      architectModel: true,
    }));
  };

  useEffect(() => {
    if (selectBoxOpen && architect?.length === 0) {
      dispatch(fetchArchitect());
    }
  }, [dispatch, selectBoxOpen, architect]);

  useEffect(() => {
    if (loading === "fulfilled" && architects && architects?.length > 0) {
      setArchitect(architects);
    }
  }, [loading, architects]);

  const handleAssignClick = () => {
    dispatch(
      assignArchitectOnOrder({
        id: orderId,
        orderdata: {
          archId: selectedArchitect?._id,
        },
        orderItemId,
        productType: productType,
        selectedArchitect,
      })
    );

    setOpenModal((prevState) => ({
      ...prevState,
      architectModel: false,
    }));
  };
  return (
    <>
      {openModal?.cancelModel && (
        <CancelModal>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" textAlign="center" color="error">
                Cancel Order
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="outlined-basic"
                size="small"
                placeholder="Cancel Reason"
                variant="outlined"
                required
                style={{ width: "100%" }}
                value={reason || ""}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: "10px" }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
              <Button
                color="success"
                sx={{ marginRight: 1 }}
                variant="contained"
                onClick={handleSubmitClick}
              >
                Submit
              </Button>
              <Button
                color="warning"
                variant="contained"
                type="button"
                onClick={() => {
                  // setEditedTag({ id: "", tagName: "" });
                  setOpenModal((prevState) => ({
                    ...prevState,
                    cancelModel: false,
                  }));
                }}
              >
                Cancle
              </Button>
            </Grid>
          </Grid>
        </CancelModal>
      )}

      {openModal?.architectModel && (
        <CancelModal>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              textAlign="center"
              style={{ zIndex: "999" }}
            >
              <Typography variant="h6" textAlign="center" color="error">
                Assign Architect
              </Typography>
            </Grid>
            <Autocomplete
              fullWidth
              open={selectBoxOpen}
              id="asynchronous-demo"
              value={selectedArchitect || null}
              label="Select Status"
              name="staus"
              onOpen={() => {
                setSelectBoxOpen(true);
              }}
              onClose={() => {
                setSelectBoxOpen(false);
              }}
              options={architect || []}
              getOptionLabel={(option) => option?.Name}
              isOptionEqualToValue={(option, value) =>
                option?._id === value?._id
              }
              onChange={(event, value) => setSelectArchitect(value)}
              // renderInput={(params) => (
              //   <TextField {...params} label="Select Architect" />
              // )}
              loading={loading === "pending"}
              // PopperComponent={CustomPopper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Architect"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading === "pending" ? (
                          <CircularProgress color="inherit" />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            container
            style={{ marginTop: "10px" }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
              <Button
                color="success"
                sx={{ marginRight: 1 }}
                variant="contained"
                onClick={handleAssignClick}
              >
                Assign
              </Button>
              <Button
                color="warning"
                variant="contained"
                type="button"
                onClick={() => {
                  // setEditedTag({ id: "", tagName: "" });
                  setOpenModal((prevState) => ({
                    ...prevState,
                    architectModel: false,
                  }));
                }}
              >
                Cancle
              </Button>
            </Grid>
          </Grid>
        </CancelModal>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openZoomModal}>
          <Box sx={style}>
            <IconButton
              aria-label="fingerprint"
              color="success"
              sx={{ float: "right", top: 0, right: 0, position: "absolute" }}
              onClick={handleCloseZoomModal}
            >
              <CloseIcon />
            </IconButton>
            <ProductMagnification
              images={productImages}
              activeIndex={activeIndex}
              path="status"
            />
          </Box>
        </Fade>
      </Modal>

      <StatusModal handleClose={handleClose} width="30%" show={open} left="50%">
        <section className="root12">
          <figure className="figure12">
            {/* <img src="#" alt="" /> */}
            <figcaption>
              {status && status?.singleProductId && (
                <h2 style={{ fontWeight: "600" }}>
                  {status?.singleProductId?.ProductName}
                </h2>
              )}
              {status && status?.customizeProduct && (
                <h2 style={{ fontWeight: "600" }}>
                  {status?.customizeProduct?.ProductName}
                </h2>
              )}
              {status &&
                (status?.singleDotProductId ||
                  status?.customizeDotProductId) && (
                  <h2 style={{ fontWeight: "600" }}>{status?.name}</h2>
                )}
              {status && status?.customizedComboId && (
                <h2 style={{ fontWeight: "600" }}>
                  {status?.customizedComboId?.Name}
                </h2>
              )}
              {console.log("status", status)}
              <h2>₹ {status?.productAmount}</h2>
            </figcaption>
          </figure>
          <div className="order-track">
            <div className="order-track-step">
              <div className="order-track-status">
                <span className="order-track-status-dot" />
                <span className="order-track-status-line" />
              </div>
              <div className="order-track-text">
                <p
                  className="order-track-text-stat"
                  style={{ fontWeight: "600" }}
                >
                  Order Received
                </p>
                <span
                  className="order-track-text-sub"
                  style={{ color: "darkgray", fontWeight: "500" }}
                >
                  We Received your order
                </span>
                <br></br>
                <span
                  className="order-track-text-sub"
                  style={{ color: "darkgray", fontWeight: "500" }}
                >
                  {formateDate(orderProducts?.createdAt)}
                </span>
              </div>
            </div>
            {status &&
              status?.status &&
              status?.status?.length > 0 &&
              status?.status?.map((data) => (
                <div key={data?._id} className="order-track-step mb-2">
                  <div className="order-track-status">
                    <span className="order-track-status-dot" />
                    <span className="order-track-status-line" />
                  </div>
                  <div
                    className="order-track-text"
                    style={{
                      overflowY:
                        (data?.message || "").length > 100
                          ? "scroll"
                          : "visible",
                      maxHeight:
                        (data?.message || "").length > 100 ? "200px" : "auto",
                    }}
                  >
                    <p
                      className="order-track-text-stat"
                      style={{ fontWeight: "600" }}
                    >
                      {data?.status?.name}
                    </p>
                    <span
                      className="order-track-text-sub"
                      style={{ color: "darkgray", fontWeight: "500" }}
                    >
                      {data?.message}
                    </span>
                    <br />
                    <span
                      className="order-track-text-sub"
                      style={{ color: "darkgray", fontWeight: "500" }}
                    >
                      {formateDate(data?.createdAt)}
                    </span>
                  </div>
                  {data?.statusImage &&
                    data?.statusImage?.length !== 0 &&
                    data?.statusImage.map((image, index) => (
                      <React.Fragment key={index}>
                        <img
                          src={`${REACT_APP_URL}/images/status/${image}`}
                          alt="Preview"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                          onClick={() => handleOpenZoomModal()}
                        />
                        &nbsp;
                      </React.Fragment>
                    ))}
                </div>
              ))}
          </div>
        </section>
      </StatusModal>

      <div
        className="row"
        style={{
          backgroundImage: `url(${BackgroundImageRight})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: "0px",
        }}
      >
        <div className="container" style={{ width: "1200px" }}>
          <div className="row">
            <div
              className="container"
              style={{ width: "1200px", paddingTop: "50px" }}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#fff",
                        color: "#475B52",
                        width: "15%",
                      }}
                    >
                      Order Id
                    </th>
                    <th
                      style={{
                        backgroundColor: "#fff",
                        color: "#475B52",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        backgroundColor: "#fff",
                        color: "#475B52",
                      }}
                    >
                      Mobile No.
                    </th>
                    {orderProducts?.alternatePhoneNumber && (
                      <th
                        style={{
                          backgroundColor: "#fff",
                          color: "#475B52",
                        }}
                      >
                        Alternate Mobile No.
                      </th>
                    )}
                    <th
                      style={{
                        backgroundColor: "#fff",
                        color: "#475B52",
                      }}
                    >
                      Alternate Mobile No.
                    </th>
                    <th
                      style={{
                        backgroundColor: "#fff",
                        color: "#475B52",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{orderProducts?.orderId}</td>
                    <td>
                      {orderProducts?.firstName} {orderProducts?.lastName}
                    </td>
                    <td>{orderProducts?.phoneNumber}</td>
                    {orderProducts?.alternatePhoneNumber && (
                      <td>{orderProducts?.alternatePhoneNumber}</td>
                    )}
                    <td>{orderProducts?.alternatePhoneNumber}</td>
                    <td>{formateAmount(orderProducts?.amount)}</td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#475B52",
                        color: "#fff",
                      }}
                    >
                      Delivery Address
                    </th>
                    <td colSpan={4}>
                      {" "}
                      {orderProducts?.addressLine1}{" "}
                      {orderProducts?.addressLine2}
                    </td>
                  </tr>
                </tbody>
              </table>

              <br></br>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#475B52",
                        color: "#fff",
                      }}
                    >
                      Image
                    </th>
                    <th
                      style={{
                        color: "#fff",
                        backgroundColor: "#475B52",
                      }}
                    >
                      Product Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        backgroundColor: "#475B52",
                      }}
                    >
                      Price
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        backgroundColor: "#475B52",
                      }}
                    >
                      Qty
                    </th>
                    {isAdmin && (
                      <th
                        style={{
                          textAlign: "center",
                          color: "#fff",
                          backgroundColor: "#475B52",
                        }}
                      >
                        Arch. Name
                      </th>
                    )}
                    <th
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        backgroundColor: "#475B52",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singleProducts &&
                    singleProducts?.length > 0 &&
                    singleProducts.map((product, index) => (
                      <SingleProductCard
                        key={index}
                        product={product}
                        orderProducts={orderProducts}
                        setStatus={setStatus}
                        handleCancleClick={handleCancleClick}
                        handleAssignArchitectClick={handleAssignArchitectClick}
                        isAdmin={isAdmin}
                      />
                    ))}
                  {customizeProducts &&
                    customizeProducts?.length > 0 &&
                    customizeProducts.map((product) => (
                      <CustomizeProductCard
                        key={product?._id}
                        product={product?.customizeProduct}
                        combination={product}
                        orderProducts={orderProducts}
                        setStatus={setStatus}
                        handleCancleClick={handleCancleClick}
                        handleAssignArchitectClick={handleAssignArchitectClick}
                        isAdmin={isAdmin}
                      />
                    ))}

                  {singleDotProducts &&
                    singleDotProducts?.length > 0 &&
                    singleDotProducts?.map((p) => (
                      <SingleDotProductCard
                        key={p._id}
                        product={p}
                        setStatus={setStatus}
                        orderProducts={orderProducts}
                        handleCancleClick={handleCancleClick}
                        handleAssignArchitectClick={handleAssignArchitectClick}
                        isAdmin={isAdmin}
                      />
                    ))}

                  {customizeDotProducts &&
                    customizeDotProducts?.length > 0 &&
                    customizeDotProducts?.map((p) => (
                      <CustomizeDotProductCard
                        key={p._id}
                        product={p}
                        setStatus={setStatus}
                        orderProducts={orderProducts}
                        handleCancleClick={handleCancleClick}
                        handleAssignArchitectClick={handleAssignArchitectClick}
                        isAdmin={isAdmin}
                      />
                    ))}

                  {customizeComboProducts &&
                    customizeComboProducts?.length > 0 &&
                    customizeComboProducts.map((product) => (
                      <CustomizedCombinationCard
                        key={product?._id}
                        setStatus={setStatus}
                        data={product}
                        product={product?.customizedComboId}
                        selectedCustomizedProduct={
                          product?.customizedComboRectangle
                        }
                        orderProducts={orderProducts}
                        handleCancleClick={handleCancleClick}
                        handleAssignArchitectClick={handleAssignArchitectClick}
                        isAdmin={isAdmin}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderProducts;
